import React, { useState, useRef, ReactElement } from "react";
import { Autocomplete, TextField, Chip, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";

interface SearchBarProps {
  onSearch: (tags: string[]) => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const inputRef = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const allowSearch = tags.every((value) => value.length >= 3);

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string[], reason: string) => {
    if (reason === "blur") {
      return;
    }
    if (reason === "createOption") {
      if (inputValue.length > 2 && allowSearch) {
        setTags(newValue);
        onSearch(newValue);
        return;
      }
      setInputValue(newValue[newValue.length - 1]);
      return;
    }
    if (reason === "removeOption") {
      setTags(newValue);
      onSearch(newValue);
      return;
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputValue.length > 2 && allowSearch) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onSearch(newTags);
      setInputValue("");
      inputRef.current?.blur();
    }
  };
  const handleSearchClick = () => {
    if (inputValue.length > 2 && allowSearch) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onSearch(newTags);
      setInputValue("");
      inputRef.current?.blur();
    }
  };
  const handleClear = () => {
    setTags([]);
    onSearch([]);
    setInputValue("");
    inputRef.current?.blur();
  };

  return (
    <Box sx={{ width: "auto" }}>
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={tags}
        disableClearable
        fullWidth
        onChange={handleChange}
        inputValue={inputValue}
        limitTags={8}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyPress}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip label={option} {...getTagProps({ index })} size="small" key={`${option}-${index}`} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="outlined"
            size="small"
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              placeholder: isEmpty(tags) && isEmpty(inputValue) ? "Search" : "",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={handleSearchClick}>
                    <SearchIcon />
                  </IconButton>
                  {(inputValue || !isEmpty(tags)) && (
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
export default SearchBar;
