import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ChevronDownIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2719_30328)">
          <path
            d="M5.99999 11.1428C5.78067 11.1428 5.56124 11.0591 5.39409 10.8917L0.251238 5.74888C-0.0835833 5.41405 -0.0835833 4.87164 0.251238 4.53682C0.586059 4.202 1.12847 4.202 1.46329 4.53682L5.99999 9.07499L10.5375 4.53749C10.8723 4.20267 11.4147 4.20267 11.7495 4.53749C12.0844 4.87231 12.0844 5.41472 11.7495 5.74954L6.60668 10.8924C6.43927 11.0598 6.21963 11.1428 5.99999 11.1428Z"
            fill="#037CB7"
          />
        </g>
        <defs>
          <clipPath id="clip0_2719_30328">
            <rect width="12" height="13.7143" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default ChevronDownIcon;
