import React, { useState, useEffect } from "react";

import { Grid, Typography, Tabs, Tab, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { FormData } from "../pages/WebForm";
import CustomTextField from "../Elements/CustomTextField";

interface EmployeeSettingsProps {
  data: FormData;
  setData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: any;
}

const EmployeeSettings: React.FC<EmployeeSettingsProps> = ({ data, setData, errors }) => {
  const [magicLinkOption, setMagicLinkOption] = useState<string>("no_option");

  useEffect(() => {
  }, [magicLinkOption]);

  useEffect(() => {
    const userCreationByEmailDomain = data?.employeeSetting?.magicLinkOptions?.userCreationByEmailDomain;
    const userCreationByCompanyCode = data?.employeeSetting?.magicLinkOptions?.userCreationByCompanyCode;
    if (userCreationByEmailDomain !== undefined || userCreationByCompanyCode !== undefined) {
      if (userCreationByEmailDomain) {
        setMagicLinkOption("emailCode");
        return;
      }
      if (userCreationByCompanyCode) {
        setMagicLinkOption("companyCode");
        return;
      }
    }
  }, [data]);

  const handleChange = (_: any, value: number) => {
    setData((prevData) => {
      return {
        ...prevData,
        employeeSetting: {
          ...prevData.employeeSetting,
          magicLinkSign: value === 0,
          censusFileSign: value === 1,
        },
      };
    });
  };

  const handleMagicLinkOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const magicLinkOption = event.target.value;
    setData((prevData) => {
      return {
        ...prevData,
        employeeSetting: {
          ...prevData.employeeSetting,
          magicLinkOptions: {
            userCreationByCompanyCode: magicLinkOption === "companyCode",
            userCreationByEmailDomain: magicLinkOption === "emailCode",
          },
        },
      };
    });
  };

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setData((prevData: FormData) => {
      const domains = prevData.employeeSetting.domains || [""];
      domains[index] = event.target.value;
      return { ...prevData, employeeSetting: { ...prevData.employeeSetting, domains } };
    });
  };

  const addNewField = (type: string) => {
    setData((prevData: FormData) => {
      if (type === "contactDetails") {
        let field = prevData["contactDetails"] || [];
        field.push({ name: "", email: "", contactnumber: "" });
        return { ...prevData, [type]: field };
      } else if (type === "domains") {
        let field = prevData["employeeSetting"]["domains"] || [];
        field.push("");
        return { ...prevData, employeeSetting: { ...prevData.employeeSetting, [type]: field } };
      }
      return { ...prevData };
    });
  };

  const removeField = (type: string, index: number) => {
    setData((prevData: FormData) => {
      if (type === "contactDetails") {
        let field = prevData["contactDetails"] || [];
        field.splice(index, 1);
        return { ...prevData, [type]: field };
      } else if (type === "domains") {
        let field = prevData["employeeSetting"]["domains"] || [];
        field.splice(index, 1);
        return { ...prevData, employeeSetting: { ...prevData.employeeSetting, [type]: field } };
      }
      return { ...prevData };
    });
  };

  const renderMagicLink = (formData: FormData) => {
    return (
      <Grid>
        <RadioGroup
          row
          value={magicLinkOption}
          onChange={handleMagicLinkOptions}
          aria-labelledby="magic-link"
          name="magic-link"
        >
          <FormControlLabel
            sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}
            value="companyCode"
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "24px !important" } }} />}
            label="Company Code"
          />
          <FormControlLabel
            sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}
            value="emailCode"
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "24px !important" } }} />}
            label="Email Domain"
          />
        </RadioGroup>
        {formData?.employeeSetting?.magicLinkOptions?.userCreationByEmailDomain ? (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {data.employeeSetting.domains &&
              data.employeeSetting.domains.map((domain, index) => {
                const domainError = (errors.domains && errors.domains[index]) || "";
                return (
                  <React.Fragment key={"domain" + index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomTextField
                        error={domainError.length > 0 ? true : false}
                        helperlabel={
                          index === 0
                            ? "Include '@' before each domain (eg. @acme.com). You may add multiple by selecting 'add more'"
                            : ""
                        }
                        helperText={domainError.length > 0 ? domainError : ""}
                        hidelabel={index === 0 ? false : true}
                        label="Email Domain(s)"
                        name="domains"
                        width={0.85}
                        sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px" }}
                        required={data.userCreationByEmailDomain}
                        value={domain}
                        changeHandler={(e) => handleDomainChange(e, index)}
                      />
                      {index !== 0 && (
                        <Button
                          color="error"
                          startIcon={<DeleteIcon />}
                          className="MuiAddMore-button"
                          variant="text"
                          sx={{ mt: 2, fontSize: "16px" }}
                          onClick={() => removeField("domains", index)}
                        >
                          Remove
                        </Button>
                      )}
                      {index === 0 && (
                        <Button
                          variant="text"
                          sx={{ mt: 5, fontSize: "16px" }}
                          className="MuiAddMore-button"
                          startIcon={<AddIcon />}
                          onClick={() => addNewField("domains")}
                        >
                          Add More
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h2" sx={{ fontSize: "32px", fontWeight: "300", lineHeight: "38px", marginBottom: "23px" }}>
        Employee Settings
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: "18px", fontWeight: "400", lineHeight: "28px", marginBottom: "23px" }}
      >
        How will employees sign up for the platform?
      </Typography>
      <Grid sx={{ width: "230px", height: "40px", marginBottom: "16px" }}>
        <Tabs
          value={data?.employeeSetting?.magicLinkSign ? 0 : 1}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            ".MuiTabs-root": {
              minHeight: "40px",
            },
            ".MuiTabs-flexContainer": {
              backgroundColor: "#fff",
              border: "1px solid #015294",
              borderRadius: "6px",
              height: "40px !important",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            },
            ".Mui-selected": {
              height: "40px !important",
              backgroundColor: "#015294",
              color: "#fff !important",
              border: "1px solid #015294",
            },
            ".MuiButtonBase-root": {
              flex: 1,
              height: "40px",
              transition: "0.1s",
              color: "#015294",
              padding: "0px 4px",
              borderRadius: "0px ",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              "&:first-of-type": { borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" },
              "&:last-of-type": { borderTopRightRadius: "6px", borderBottomRightRadius: "6px" },
            },
          }}
        >
          <Tab label="Magic Link" />
          <Tab label="Census File" />
        </Tabs>
      </Grid>
      {data?.employeeSetting?.magicLinkSign ? (
        renderMagicLink(data)
      ) : (
        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "20px" }}>
          After saving your company details, download the template and upload your employee list on the next screen.
        </Typography>
      )}
    </>
  );
};

export default EmployeeSettings;
