import React, { useState, useEffect } from "react";

import { Grid, Typography, IconButton, CircularProgress, LinearProgress } from "@mui/material";
import { AxiosProgressEvent } from "axios";
import { isEmpty } from "lodash";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import ErrorIcon from "@mui/icons-material/Error";

import { formatBytes } from "../../Utils/helperUtils";
import { onFileUpload, onRemoveCensusFile } from "../../Utils/apiUtils";
import { CensusFileResponse, CensusFileInterface } from "../../Utils/interfaces";

interface FileUploadInterface {
  file: CensusFileInterface;
  setFiles: React.Dispatch<React.SetStateAction<CensusFileInterface[]>>;
  companyId: string;
  setCensusFilesResponse: React.Dispatch<React.SetStateAction<CensusFileResponse[]>>;
  setAlert: React.Dispatch<any>;
  setIsLoadingChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileUpload: React.FC<FileUploadInterface> = ({
  file,
  setFiles,
  companyId,
  setCensusFilesResponse,
  setAlert,
  setIsLoadingChanges,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [formDataState, setFormDataState] = useState<FormData | null>(null);

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
    setUploadPercentage(percentCompleted);
  };

  const postFileUpload = async (formData: FormData) => {
    const controller = new AbortController();
    setAbortController(controller);

    const response = await onFileUpload(formData, controller.signal, onUploadProgress);
    setIsLoading(false);
    if (response.type === "success") {
      if (response.data.processed) {
        setCensusFilesResponse([response.data]);
        setIsLoadingChanges(false);
        return;
      }
      setHasError(true);
      setAlert({
        open: true,
        type: "error",
        message: `Unexpected Error while uploading. Please try again.`,
      });
    }
    if (response.type === "error") {
      setHasError(true);
      setAlert({
        open: true,
        type: "error",
        message: `Unexpected Error while uploading. Please try again.`,
      });
    }
  };

  const handleDeleteFile = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    abortController?.abort();
    setIsLoadingChanges(true);
    setFiles((prevFiles) => {
      return prevFiles.filter((item) => item.id !== file.id);
    });
    const response = await onRemoveCensusFile({ FileId: file.id, FileName: file.file.name, companyId });

    if (response.type === "success" && response.data) {
      const data: CensusFileResponse = response.data;
      let isAllTabsEmpty: boolean[] = [];
      Object.values(data).forEach((item) => {
        if (typeof item === "object") {
          isAllTabsEmpty.push(isEmpty(item.listResult));
        }
      });
      if (isAllTabsEmpty.every((item) => item)) {
        setCensusFilesResponse([]);
        return;
      }
      setCensusFilesResponse([data]);
    }

    setIsLoadingChanges(false);
  };

  const handleRetry = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (formDataState) {
      setIsLoading(true);
      setHasError(false);
      postFileUpload(formDataState);
    }
  };

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("ExcelFile", file.file);
      formData.append("FileId", file.id);
      formData.append("FileName", file.file.name);
      formData.append("CompanyId", companyId);

      setFormDataState(formData);
      postFileUpload(formData);
    }
  }, [file, companyId]);

  return (
    <Grid
      sx={{
        mt: "16px",
        minWidth: "200px",
        minHeight: "46px",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        display: "flex",
        padding: "8px 4px",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <Grid sx={{ display: "flex" }}>
          {isLoading ? (
            <CircularProgress size="16px" sx={{ color: "#aaaaaa", mr: "6px" }} />
          ) : (
            <AttachFileRoundedIcon sx={{ color: "#757575", fonSize: "12px" }} />
          )}
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              color: `${hasError ? "#b30303" : "#525252"} !important`,
              width: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              overflow: "hidden",
              whiteSpace: "wrap",
              textOverflow: "ellipsis",
            }}
          >
            {file.file.name}
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "flex-start" }}>
          {hasError ? (
            <>
              <ErrorIcon sx={{ fontSize: "12px  !important", color: "#b30303 !important", mr: "4px" }} />
              <IconButton
                sx={{ alignItems: "flex-start !important", padding: "0px" }}
                size="small"
                onClick={handleRetry}
                disableRipple
              >
                <RefreshRoundedIcon sx={{ color: "#757575", fonSize: "12px" }} />
              </IconButton>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#757575 !important",
                  width: "100%",
                  mr: "2px",
                }}
              >
                {formatBytes(file.file.size)}
              </Typography>
              {!isLoading && <CheckCircleRoundedIcon sx={{ color: "#5e9e42", fonSize: "12px", mr: "2px" }} />}
            </>
          )}
          <IconButton
            sx={{ alignItems: "flex-start !important", padding: "0px" }}
            size="small"
            onClick={handleDeleteFile}
            disableRipple
          >
            <CloseRoundedIcon sx={{ color: "#757575", fonSize: "12px" }} />
          </IconButton>
        </Grid>
      </Grid>
      {isLoading && (
        <Grid sx={{ width: "100%", height: "2px", pt: "8px" }}>
          <LinearProgress
            sx={{ height: "2px", backgroundColor: "#99b9d4" }}
            variant="determinate"
            value={uploadPercentage}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FileUpload;
