import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DownloadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 1V8.59375L11.2812 6.3125C11.6562 5.90625 12.3125 5.90625 12.6875 6.3125C13.0938 6.6875 13.0938 7.34375 12.6875 7.71875L8.6875 11.7188C8.3125 12.125 7.65625 12.125 7.28125 11.7188L3.28125 7.71875C2.875 7.34375 2.875 6.6875 3.28125 6.3125C3.65625 5.90625 4.3125 5.90625 4.6875 6.3125L7 8.59375V1C7 0.46875 7.4375 0 8 0C8.53125 0 9 0.46875 9 1ZM2 11H5.15625L6.5625 12.4375C7.34375 13.2188 8.625 13.2188 9.40625 12.4375L10.8125 11H14C15.0938 11 16 11.9062 16 13V14C16 15.125 15.0938 16 14 16H2C0.875 16 0 15.125 0 14V13C0 11.9062 0.875 11 2 11ZM13.5 14.25C13.9062 14.25 14.25 13.9375 14.25 13.5C14.25 13.0938 13.9062 12.75 13.5 12.75C13.0625 12.75 12.75 13.0938 12.75 13.5C12.75 13.9375 13.0625 14.25 13.5 14.25Z"
          fill="#037CB7"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIcon;
