import React, { useState, useEffect, useMemo } from "react";

import {
  styled,
  Paper,
  Pagination,
  PaginationItem,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { isEmpty } from "lodash";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import ChevronDownIcon from "../../assets/chevronDownIcon";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    backgroundColor: "#EEF2F5",
    borderBottom: "1px solid #8BA1B6",
    color: "#525252",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    borderBottom: "1px solid #CEE1F0 !important",
    color: "#525252",
  },
}));

interface Column<T> {
  header: string;
  accessor?: keyof T;
  render?: (row: T) => React.ReactNode;
  action?: (row: T) => React.ReactNode;
}

interface CustomTableProps<T> {
  columns: Column<T>[];
  rows: T[];
  onDelete?: (row: T) => void;
  rowsPerPageArray?: number[];
  totalCountRows?: number;
  page?: number;
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  handlePageChangeProp?: (value: number) => void;
}

const customNextIcon = () => (
  <>
    <Typography variant="body1" sx={{ fontSize: "12px", textDecoration: "underline", color: "inherit !important" }}>
      Next
    </Typography>
    <KeyboardArrowRightIcon />
  </>
);

const customPrevIcon = () => (
  <>
    <KeyboardArrowLeftIcon />
    <Typography variant="body1" sx={{ fontSize: "12px", textDecoration: "underline", color: "inherit !important" }}>
      Prev
    </Typography>
  </>
);

const CustomTable = <T extends Record<string, string>>({
  columns,
  rows,
  rowsPerPage,
  rowsPerPageArray = [10],
  totalCountRows,
  setRowsPerPage,
  setPage,
  handlePageChangeProp,
  page,
}: CustomTableProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(0);

  const totalRowsPerPage = useMemo(
    () => (rowsPerPage ? rowsPerPage.toString() : rowsPerPageState.toString()),
    [rowsPerPage, rowsPerPageState]
  );

  const totalPage = useMemo(() => (page ? page : currentPage), [page, currentPage]);

  useEffect(() => {
    const totalRows = totalCountRows ? totalCountRows : rows.length;
    const totalRowPerPage = rowsPerPage ? rowsPerPage : rowsPerPageState;
    if (!rowsPerPage) {
      setCurrentPage(1);
    }

    if (totalRowPerPage > 0) {
      setTotalPageCount(Math.ceil(totalRows / totalRowPerPage));
    }
  }, [rows, rowsPerPageState, rowsPerPage]);

  useEffect(() => {
    if (setRowsPerPage) {
      return;
    }

    setRowsPerPageState(rowsPerPageArray[0]);
  }, [rowsPerPageArray]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    handlePageChangeProp && handlePageChangeProp(value);
  };

  const handleRowsPerPageState = (event: SelectChangeEvent) => {
    if (setRowsPerPage) {
      setRowsPerPage(Number(event.target.value));
      setPage && setPage(0);
      return;
    }

    setRowsPerPageState(Number(event.target.value));
  };

  const renderCell = (column: Column<T>, row: T) => {
    if (column.action) {
      return column.action(row);
    }

    if (column.render) {
      return column.render(row);
    }

    if (column.accessor) {
      return row[column.accessor];
    }
  };

  const totalRows = useMemo(() => {
    if (rowsPerPage) {
      return rows;
    }
    return rows.slice(currentPage * rowsPerPageState - rowsPerPageState, currentPage * rowsPerPageState);
  }, [rowsPerPage, currentPage, rowsPerPageState, rows]);

  return (
    <Grid sx={{ position: "relative" }}>
      {rowsPerPageArray.length > 1 && (
        <Grid
          sx={{ display: "flex", alignItems: "center", gap: "12px", position: "absolute", right: "0px", top: "-63px" }}
        >
          <InputLabel id="items-per-page-table" sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>
            Items per page
          </InputLabel>
          <Select
            id="items-per-page-table"
            sx={{ height: "40px", width: "84px" }}
            value={totalRowsPerPage}
            IconComponent={ChevronDownIcon}
            onChange={handleRowsPerPageState}
          >
            {rowsPerPageArray.map((item, index) => {
              return (
                <MenuItem value={item} key={`${index}-${item}`}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      )}
      <TableContainer component={Paper} sx={{ borderRadius: "10px", boxShadow: "0px 4px 10px 0px #2E2E2E1A" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index}>{column.header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <StyledTableCell key={colIndex}>{renderCell(column, row)}</StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEmpty(rows) && (
        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Pagination
            color="primary"
            shape="rounded"
            sx={{
              pt: "20px",
              ".MuiButtonBase-root": {
                color: "#037cb7",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
              },
              ".Mui-selected": {
                color: "#fff",
              },
              ".Mui-disabled": {
                color: "#525252 !important",
              },
            }}
            count={totalPageCount}
            siblingCount={10}
            page={totalPage}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  next: customNextIcon,
                  previous: customPrevIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CustomTable;
