import React, { useState, useEffect } from "react";
import CustomTextField from "../Elements/CustomTextField";
import CustomDropdownField from "../Elements/CustomDropdownField";
import Typography from "@mui/material/Typography";
import { Button, FormControlLabel, Switch } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as _ from "lodash";
import {
  getStates,
  updateEmployeeDetails,
  getEmployeeDetails,
  getCompanyDetails,
  addEmployeeDetails,
  sendEmail,
} from "../../Utils/apiUtils";
import { validationSchema } from "../../Utils/Constants";
import "../Elements/globalStyles.css";
import CustomAlert from "../Elements/CustomAlert";
import Box from "@mui/system/Box";
import { useNavigate, useLocation } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { emailIsValid } from "../../Utils/helperUtils";

type formData = {
  [key: string]: string | number | object | undefined;
  firstname: string;
  lastname: string;
  email: string;
  contactNumber: string;
  state: string;
  companyName: string;
  personalEmail: string;
  personalEmailCode: string;
  companyCode: string;
};
type CompanyDetails = {
  [key: string]: string | number | boolean | object;
  company: string;
  status: string;
  contactDetails: Array<{ name: string; email: string; contactnumber: string }>;
  domains: Array<string>;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  companySize: string;
  journeys: any;
  companyUrl: string;
  companyCode: string;
  integrations: Array<string>;
  partnerRanking: any;
  userCreationByCompanyCode: boolean;
  userCreationByEmailDomain: boolean;
  employeeSetting: {
    magicLinkOptions: {
      userCreationByCompanyCode: boolean;
      userCreationByEmailDomain: boolean;
    };
    domains: string[];
    magicLinkSign: boolean;
    censusFileSign: boolean;
  };
};
const initialFormData = {
  firstname: "",
  lastname: "",
  email: "",
  personalEmail: "",
  personalEmailCode: "",
  contactNumber: "",
  state: "",
  companyName: "",
  companyCode: "",
};
const initialCompanyDetails = {
  company: "",
  status: "Active",
  contactDetails: [{ name: "", email: "", contactnumber: "" }],
  domains: [""],
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  companySize: "",
  journeys: {
    caregiving: {
      enabled: false,
      partners: {
        partner: [],
        configuration: [],
      },
    },
    parenting: {
      enabled: false,
      partners: {
        partner: [],
        configuration: [],
      },
    },
    wellness: {
      enabled: false,
      partners: {
        partner: [],
        configuration: [],
      },
    },
  },
  companyUrl: "",
  companyCode: "",
  integrations: [],
  partnerRanking: {},
  userCreationByCompanyCode: false,
  userCreationByEmailDomain: false,
  employeeSetting: {
    magicLinkOptions: {
      userCreationByCompanyCode: true,
      userCreationByEmailDomain: false,
    },
    domains: [""],
    magicLinkSign: true,
    censusFileSign: false,
  },
};

const EmployeeEditForm = () => {
  const [data, setData] = useState<formData>(initialFormData);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(initialCompanyDetails);
  const [errors, setErrors] = useState<any>({});
  const [stateOptions, setStates] = useState<any>([]);
  const [alert, setAlert] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    getStates().then((res: any) => setStates(res));
  }, []);

  //Load company details when in edit
  useEffect(() => {
    if (location.state && location.state.type === "edit") {
      getEmployeeDetails(location.state.companyId, location.state.id).then((res: any) => {
        setData(res);
      });
      getCompanyDetails(location.state.companyId).then((res: any) => {
        setCompanyDetails(res);
      });
    } else if (location.state && location.state.type === "add") {
      getCompanyDetails(location.state.companyId).then((res: any) => {
        setCompanyDetails(res);
        setData({ ...data, companyName: res.company, companyCode: res.companyCode });
      });
    }
  }, [location.state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isInputsValid = validateForm();
    if (isInputsValid) {
      setLoading(true);
      if (location.state.type === "edit") {
        updateEmployeeDetails({ ...data, companyId: location.state.companyId }).then((res) => {
          setErrors({});
          setAlert({ type: res.type, message: res.message, open: true });
          setLoading(false);
        });
      } else if (location.state.type === "add") {
        addEmployeeDetails({ ...data, companyId: location.state.companyId }).then((res) => {
          setErrors({});
          setAlert({ type: res.type, message: res.message, open: true });
          setLoading(false);
        });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    setErrors((errors: any) => {
      errors = {};
      for (var field in data) {
        if (field === "firstname") {
          let contactErrors: any = {};
          if (_.isEmpty(data[field])) contactErrors.name = validationSchema.contactDetails.helperText.name;
          errors = { ...errors, ...contactErrors };
        } else if (field === "lastname") {
          let contactErrors: any = {};
          if (_.isEmpty(data[field])) contactErrors.lastname = validationSchema.contactDetails.helperText.lastname;
          errors = { ...errors, ...contactErrors };
        } else if (companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain && field === "email") {
          let contactErrors: any = {};
          if (_.isEmpty(data[field])) {
            contactErrors.email = validationSchema.contactDetails.helperText.email;
          } else if (!emailIsValid(data[field])) {
            contactErrors.email = validationSchema.contactDetails.helperText.emailInvalid;
          }
          errors = { ...errors, ...contactErrors };
        } else if (
          companyDetails?.employeeSetting?.magicLinkOptions.userCreationByCompanyCode &&
          field === "personalEmailCode"
        ) {
          let contactErrors: any = {};
          if (_.isEmpty(data[field])) {
            contactErrors.email = validationSchema.contactDetails.helperText.email;
          } else if (!emailIsValid(data[field])) {
            contactErrors.email = validationSchema.contactDetails.helperText.emailInvalid;
          }
          errors = { ...errors, ...contactErrors };
        } else if (
          companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain &&
          field === "personalEmail"
        ) {
          let contactErrors: any = {};
          debugger;
          if (companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain && _.isEmpty(data[field])) {
            contactErrors.personalEmail = validationSchema.contactDetails.helperText.email;
          } else if (
            companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain &&
            !emailIsValid(data[field])
          ) {
            contactErrors.personalEmail = validationSchema.contactDetails.helperText.emailInvalid;
          }
          errors = { ...errors, ...contactErrors };
        }
      }
      isValid = _.isEmpty(errors);

      return { ...errors };
    });
    return isValid;
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({});
  };

  const handleSendEmail = (type: string) => {
    let req = {
      companyId: location.state.companyId,
      id: location.state.id,
      email: data[type],
      isPersonalEmail: type === "personalEmail" ? true : false,
      isRequestedByAdmin: true,
    };
    sendEmail(req).then((res: any) => {
      setCompanyDetails(res);
      setData({ ...data, companyName: res.company });
      setAlert({ type: res.type, message: res.message, open: true });
    });
  };
  return (
    <>
      {alert.open && (
        <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />
      )}
      <Button
        variant="text"
        sx={{ mt: 2 }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/CompanyOverview", { state: { id: location.state.companyId } })}
      >
        {" "}
        Back
      </Button>
      <Box sx={{ backgroundColor: "white", padding: "35px", borderRadius: "5px", mt: 1 }}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Typography color="text.secondary" variant="h3" sx={{ mt: 4, mb: 3 }}>
            Employee Details
          </Typography>

          <CustomTextField
            error={errors.name ? true : false}
            helperText={errors.name ? errors.name : ""}
            label="First Name"
            name="firstname"
            width={0.45}
            required={true}
            value={data.firstname}
            changeHandler={handleChange}
          />
          <CustomTextField
            error={errors.lastname ? true : false}
            helperText={errors.lastname ? errors.lastname : ""}
            label="Last Name"
            name="lastname"
            width={0.45}
            required={true}
            value={data.lastname}
            changeHandler={handleChange}
          />
          {companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain && (
            <CustomTextField
              error={errors.email ? true : false}
              helperText={errors.email ? errors.email : ""}
              label="Email Address / Username"
              name="email"
              width={location.state.type === "edit" ? 0.32 : 0.45}
              required={true}
              value={data.email}
              changeHandler={handleChange}
            />
          )}
          {companyDetails?.employeeSetting?.magicLinkOptions.userCreationByCompanyCode && (
            <CustomTextField
              error={errors.personalEmailCode ? true : false}
              helperText={errors.personalEmailCode ? errors.personalEmailCode : ""}
              label="Email Address / Username"
              name="personalEmailCode"
              width={location.state.type === "edit" ? 0.32 : 0.45}
              required={true}
              value={data.personalEmailCode}
              changeHandler={handleChange}
            />
          )}
          {location.state.type === "edit" && (
            <Button
              variant="text"
              sx={{ mt: 3, mr: "20px" }}
              startIcon={<SendIcon />}
              onClick={() => handleSendEmail("email")}
            >
              {" "}
              Send Login Link
            </Button>
          )}
          {companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain && (
            <CustomTextField
              error={errors.personalEmail ? true : false}
              helperText={errors.personalEmail ? errors.personalEmail : ""}
              label="Personal/Alternate email"
              name="personalEmail"
              width={location.state.type === "edit" ? 0.32 : 0.45}
              required={companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain}
              value={data.personalEmail}
              changeHandler={handleChange}
            />
          )}
          {location.state.type === "edit" &&
            companyDetails?.employeeSetting?.magicLinkOptions.userCreationByEmailDomain && (
              <Button
                variant="text"
                sx={{ mt: 3 }}
                startIcon={<SendIcon />}
                onClick={() => handleSendEmail("personalEmail")}
              >
                {" "}
                Send Login Link
              </Button>
            )}
          <CustomTextField
            error={errors.contactNumber ? true : false}
            helperText={errors.contactNumber ? errors.contactNumber : ""}
            type="phonenumber"
            label="Mobile Phone Number"
            name="contactNumber"
            width={0.28}
            required={false}
            value={data.contactNumber}
            changeHandler={handleChange}
          />
          <CustomDropdownField
            error={errors.state ? true : false}
            helperText={errors.state ? errors.state : ""}
            type="searchable"
            label="State"
            name="state"
            placeholder="State"
            required={false}
            options={stateOptions}
            value={data.state}
            width={0.28}
            changeHandler={handleChange}
          />
          <CustomTextField
            label="Company"
            disabled={true}
            name="company"
            width={0.95}
            required={false}
            value={data.companyName || companyDetails.company}
            changeHandler={handleChange}
          />

          <div
            style={{
              display: "flex",
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "4%",
            }}
          >
            <div>
              <Typography sx={{ fontSize: "12px", mt: 4, fontWeight: "600" }}>
                There are required fields in this form marked <span style={{ color: "#B30303" }}>*</span>
              </Typography>
            </div>
            <div>
              <Button
                variant="outlined"
                style={{ margin: "5px" }}
                onClick={() => navigate("/CompanyOverview", { state: { id: location.state.companyId } })}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                loadingIndicator="Saving�"
                variant="contained"
                style={{ margin: "5px" }}
                type="submit"
              >
                {location.state.type === "edit" ? "Save Employee Details" : "Create New Employee"}
              </LoadingButton>
            </div>
          </div>
        </form>
      </Box>
    </>
  );
};

export default EmployeeEditForm;
