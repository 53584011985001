import React from "react";

import { Grid, Typography, Divider } from "@mui/material";

import NoResults from "../../assets/no_results_plus.png";

const CensusNoEmployee: React.FC = () => {
  return (
    <Grid>
      <div style={{ paddingTop: "23px" }}>
        <Divider variant="fullWidth" className="MuiDivider-webform" />
      </div>
      <Grid
        sx={{ pt: "120px", pb: "90px" }}
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={NoResults} alt="no_results" width="125px" />
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "32px",
            pt: "24px",
            color: "#025780 !important",
          }}
        >
          No Employee have been added for this company.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>
          You can start adding them now.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CensusNoEmployee;
