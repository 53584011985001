import React, { useState, useEffect } from "react";

import { Grid, Typography, Tabs, Tab, IconButton } from "@mui/material";
import dayjs from "dayjs";

import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";

import CustomTable from "./CustomTable";
import { CensusUserData, CensusFileResponse, TabsTableInterface, TableProcess } from "../../Utils/interfaces";
import { onRemoveCensusUser } from "../../Utils/apiUtils";

interface EmployeeOverviewInterface {
  censusFilesResponse: CensusFileResponse[];
  setCensusFilesResponse: React.Dispatch<React.SetStateAction<CensusFileResponse[]>>;
  setIsLoadingChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const TabsTableStructure: TabsTableInterface[] = [
  { label: "All employee", id: "successEmployeeProcess" },
  { label: "New", id: "newEmployeeProcess" },
  { label: "Updated", id: "updateEmployeeProcess" },
  { label: "Error", id: "errorEmployeeProcess", icon: <ErrorIcon sx={{ fontSize: "16px  !important" }} /> },
  { label: "Terminated", id: "terminatedEmployeeProcess" },
];

const EmployeeTabs: React.FC<EmployeeOverviewInterface> = ({
  censusFilesResponse,
  setCensusFilesResponse,
  setIsLoadingChanges,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [rows, setRows] = useState<CensusUserData[]>([]);
  const [countRows, setCountRows] = useState<{ [key: string]: number }>({
    successEmployeeProcess: 0,
    newEmployeeProcess: 0,
    updateEmployeeProcess: 0,
    errorEmployeeProcess: 0,
    terminatedEmployeeProcess: 0,
  });

  const handleDeleteUser = async (employeeId: string, companyId: string) => {
    setIsLoadingChanges(true);
    const response = await onRemoveCensusUser({ EmployeeId: employeeId, CompanyId: companyId });
    if (response.type === "success" && response.data) {
      setCensusFilesResponse([response.data]);
    }
    setIsLoadingChanges(false);
  };

  const columns = [
    { header: "First Name", accessor: "firstname" as const },
    { header: "Last Name", accessor: "lastname" as const },
    { header: "Username", accessor: "username" as const },
    {
      header: "Termination date",
      render: (row: CensusUserData) => {
        return row.terminationDate;
      },
    },
    {
      header: "Actions",
      action: (row: CensusUserData) => (
        <IconButton
          sx={{ color: "#B30303", width: "40px", minWidth: "0" }}
          color="error"
          aria-controls="ringtone-menu"
          size="small"
          onClick={() => {
            handleDeleteUser(row.id, row.companyId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (censusFilesResponse && TabsTableStructure[currentTab]) {
      const index = TabsTableStructure[currentTab].id;
      const processRows: CensusUserData[] = [];

      censusFilesResponse.forEach((file) => {
        if (!file.processed) {
          return;
        }
        const currentTable = file[index] as TableProcess;
        processRows.push(...currentTable.listResult);
      });

      setRows(processRows);
    }
  }, [censusFilesResponse, currentTab]);

  useEffect(() => {
    const countRows: { [key: string]: number } = {
      successEmployeeProcess: 0,
      newEmployeeProcess: 0,
      updateEmployeeProcess: 0,
      errorEmployeeProcess: 0,
      terminatedEmployeeProcess: 0,
    };

    censusFilesResponse.forEach((file) => {
      if (!file.processed) {
        return;
      }
      countRows.successEmployeeProcess += file.successEmployeeProcess.listCount;
      countRows.newEmployeeProcess += file.newEmployeeProcess.listCount;
      countRows.updateEmployeeProcess += file.updateEmployeeProcess.listCount;
      countRows.errorEmployeeProcess += file.errorEmployeeProcess.listCount;
      countRows.terminatedEmployeeProcess += file.terminatedEmployeeProcess.listCount;
    });

    setCountRows(countRows);
  }, [censusFilesResponse]);

  const handleOnChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid sx={{ width: "100%" }}>
      <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "28px", pb: "20px", pt: "20px" }}>
        Employee List Upload
      </Typography>
      <Tabs
        variant="fullWidth"
        value={currentTab}
        onChange={handleOnChangeTabs}
        aria-label="employee tabs"
        sx={{
          pb: "20px",
          ".MuiButtonBase-root": {
            minHeight: "48px",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            borderBottom: "2px solid #E0E0E0",
          },
          ".Mui-selected": {
            color: currentTab === 3 ? "#B30303!important" : "#037CB7",
            borderBottom: currentTab === 3 ? "4px solid #B30303!important" : "4px solid #037CB7",
          },
          ".MuiTabs-indicator": {
            backgroundColor: currentTab === 3 ? "#B30303" : "#037CB7",
          },
        }}
      >
        {TabsTableStructure.map((tab, index) => {
          return (
            <Tab
              key={`${tab.label}-${index}`}
              label={`${tab.label} (${countRows[tab.id]})`}
              iconPosition="start"
              icon={currentTab === 3 ? tab?.icon : undefined}
            />
          );
        })}
      </Tabs>
      <Grid>
        <CustomTable<CensusUserData> rows={rows} columns={columns} />
      </Grid>
    </Grid>
  );
};

export default EmployeeTabs;
