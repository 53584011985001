import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Box from "@mui/system/Box";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import { getCompanyList } from "../../Utils/apiUtils";
import "./CompanyList.css";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchBar from "../Elements/SearchBar/Searchbar";
import NoResults from "../../assets/no_results.png";

import { escapeRegex } from "../../Utils/helperUtils";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEF2F5",
    borderBottom: "1px solid #8BA1B6",
    color: "#525252",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #CEE1F0 !important",
    color: "#525252",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
// Custom Table- Pagination Actions Component
function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        className="MuiPaginationIcon-color"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        className="MuiPaginationIcon-color"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        className="MuiPaginationIcon-color"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        className="MuiPaginationIcon-color"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
type companyDetails = {
  id: string;
  company: string;
  status: string;
  journeys: string;
  activateDate: string;
  companySize: string;
};

const CompanyList = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState<Array<companyDetails>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCompanyList().then((res: Array<companyDetails>) => {
      setLoading(false);
      setCompanyData(res);
    });
  }, []);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const highlightText = (text: string) => {
    if (tags.length === 0) {
      return text;
    }

    const escapedTags = tags.map(escapeRegex);
    const parts = text.split(new RegExp(`(${escapedTags.join("|")})`, "gi"));
    return parts.map((part, index) =>
      escapedTags.some((tag) => new RegExp(tag, "i").test(part)) ? <b key={index}>{part}</b> : part
    );
  };

  const filteredData = useMemo(
    () =>
      companyData.filter(
        (item) => tags.length === 0 || tags.some((tag) => new RegExp(escapeRegex(tag), "i").test(item.company))
      ),
    [companyData, tags]
  );

  const handleSearch = (searchTags: string[]) => {
    setTags(searchTags);
    setPage(0);
  };

  const renderTable = () => {
    if (filteredData.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Grid
              sx={{ pt: "120px", pb: "90px" }}
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={NoResults} alt="no_results" width="125px" />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "32px",
                  pt: "24px",
                  color: "#025780 !important",
                }}
              >{`Sorry! No results found for "${tags.join(", ")}"`}</Typography>
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>
                You may try looking for another company name.
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      );
    }

    return (
      rowsPerPage > 0 ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredData
    ).map((row) => (
      <StyledTableRow key={row.id}>
        <StyledTableCell component="th" scope="row">
          <a className="company-name" onClick={() => navigate("/CompanyOverview", { state: { id: row.id } })}>
            {highlightText(row.company)}
          </a>
        </StyledTableCell>
        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>{row.journeys.replace(/[,]/g, ", ")}</StyledTableCell>
        <StyledTableCell>{row.status === "Active" ? row.activateDate : ""}</StyledTableCell>
        <StyledTableCell>{row.companySize}</StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <Box sx={{ backgroundColor: "white", padding: "20px 32px", borderRadius: "5px" }}>
      <div className="companylist-header">
        <Typography color="text.secondary" variant="h5">
          Company List
        </Typography>
        <Button variant="text" sx={{ mt: 1 }} startIcon={<AddIcon />} onClick={() => navigate("/Onboarding")}>
          Create New
        </Button>
      </div>
      {isLoading ? (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid sx={{ pb: "23px" }}>
            <SearchBar onSearch={handleSearch} />
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Journeys</StyledTableCell>
                  <StyledTableCell>Activate Date</StyledTableCell>
                  <StyledTableCell>Total Employees</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTable()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            className="table-pagination"
            rowsPerPageOptions={[10, 15, 20]}
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </>
      )}
    </Box>
  );
};

export default CompanyList;
