import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./index.css";

const theme: any = {
  palette: {
    primary: {
      main: "#037CB7",
    },
    secondary: {
      main: "#D8D8D8",
    },
    error: {
      main: "#B30303",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    color: "#525252 ",
    button: {
      textTransform: "none",
    },
    allVariants: {
      color: "#525252",
    },
    h3: {
      fontSize: 32,
      fontWeight: 300,
    },
    h4: {
      fontSize: 24,
      fontWeight: 400,
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
};
// type CustomTheme = {
//   [Key in keyof typeof theme]: typeof theme[Key]
// }
// declare module '@mui/material/styles/createTheme' {
//   interface Theme extends CustomTheme { }
//   interface ThemeOptions extends CustomTheme { }
// }

// const customtheme:any= createTheme(theme)

const root = ReactDOM.createRoot(document.getElementById("adminPortal") as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={createTheme(theme)}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
