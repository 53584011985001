import * as React from "react";
import { Routes, Route } from "react-router-dom";
import WebForm from "../pages/WebForm";
import EmployeeEditForm from "../pages/EmployeeEditForm";
import CompanyList from "../pages/CompanyList";
import CompanyOverview from "../pages/CompanyOverview";
import ResourceEditor from "../pages/ResourceEditor";
import ValidationSuccess from "../pages/ValidationSuccess";
import PrivateComponent from "../Elements/PrivateComponent";
import ProtectedComponent from "../Elements/ProtectedComponent";
import Login from "../pages/Login";

export default function Router() {
  return (
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/admin/:token" element={<PrivateComponent component={<></>} />}/>
        <Route path="/validationSuccess" element={<ProtectedComponent component={ValidationSuccess} />} />
        <Route path="/Onboarding" element={<PrivateComponent component={WebForm}/>} />
        <Route path="/EditEmployee" element={<PrivateComponent component={EmployeeEditForm} />}/>
        <Route path="/companies" element={<PrivateComponent component={CompanyList} />}/>
        <Route path="/CompanyOverview" element={<PrivateComponent component={CompanyOverview} />}/>
        <Route path="/ResourceEditor" element={<PrivateComponent component={ResourceEditor}/>} />
      </Routes>
  );
}
