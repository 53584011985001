import style from "styled-components";
import { Grid, Typography, Button, Paper } from "@mui/material";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

export const FileUploadWrapper = style(Grid)<{ isDragActive: boolean }>((props) => ({
  border: `2px dashed ${!props.isDragActive ? "#AAAAAA" : "#025780"}`,
  minHeight: "72px",
  minWidth: "293px",
  borderRadius: "6px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
}));

export const CensusFilePaperContainer = style(Paper)({
  margin: "8px",
  width: "91.7%",
  padding: "24px 32px",
});
export const CensusFileHeaderContainer = style(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export const CensusFileTitle = style(Typography)({
  fontSize: "20px",
  fontWeight: "400",
  lineHeight: "28px",
  marginBottom: "16px",
});

export const CensusFileSubTitle = style(Typography)({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "26px",
});

export const CensusFileReUploadButton = style(Button)({
  height: "32px",
});

export const CensusFileReUploadIcon = style(RefreshRoundedIcon)({
  fontSize: "14px !important",
  color: "#fff !important",
});

export const CensusFileReUploadText = style(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#fff !important",
});

export const CensusFileErrorText = style(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#b30303 !important"
});

export const CensusFileUploadTitle = style(Typography)({
  fontSize: "32px",
  fontWeight: "300",
  lineHeight: "38px",
  marginBottom: "23px",
});

export const CensusFileUploadSubtitle = style(Typography)({
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "28px",
});

export const CensusFileUploadTemplateContainer = style(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "23px",
});

export const CensusFileUploadTemplateButtonText = style(Typography)({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "600",
  color: "#037CB7 !important",
});

export const CensusFileUploadButton = style(Button)({
  height: "40px",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
});

export const CensusFileUploadButtonText = style(Typography)({
  fontSize: "20px",
  fontWeight: "600",
  lineHeight: "32px",
  marginLeft: "16px",
});

export const FilesUploadContainer = style(Grid)({
  display: "flex",
  flexWrap: "wrap",
  gap: "0px 16px",
  width: "100%",
});
